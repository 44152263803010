<template>
  <div>
    <h3 v-if="this.$root.user.roles && this.$root.user.roles.includes('admin')" class="center">
      Сеансы сотрудника списком
    </h3>
    <h3 v-else class="center">Мои сеансы</h3>
    <div>
      <form v-on:submit.prevent="" class="flex">
        <div class="heap-block">
          <DateSelector label="Дата нач." :val="date_begin" @change="date_begin = $event" />
        </div>
        <div class="heap-block">
          <DateSelector label="Дата кон." :val="date_end" @change="date_end = $event" />
        </div>
        <div class="heap-block">
          <PeopleSelector
            v-if="peopleFlag"
            label="Сотрудник"
            filter="work"
            :id_peopleIn="id_people"
            @change="id_people = parseInt($event)"
          />
        </div>
        <div class="heap-block">
          <div @click.prevent="tableDataRetrieve" class="high pointer">
            <button class="btn" ref="btn">Обновить</button>
          </div>
        </div>
      </form>
    </div>

    <div v-if="loading" class="body">
      <Loader />
    </div>
    <div v-else>
      <template v-if="this.total > 0">
        <table class="t_table">
          <tr>
            <th>Дата</th>
            <th>Время</th>
            <th>Тип</th>
            <th>Комментарий</th>
            <th>Встретимся с</th>
          </tr>
          <template v-for="(row, y) in report">
            <tr :key="y">
              <td class="center" style="width: 20%">
                {{ dateFormatHuman(row.turn_date, true) }}
              </td>
              <td style="width: 20%">{{ row.time_begin }} &mdash; {{ row.time_end }}</td>
              <td style="width: 15%">{{ typeTranslate(row.turn_type) }}</td>
              <td>{{ row.turn_note }}</td>
              <td>{{ row.coworkers }}</td>
            </tr>
          </template>
        </table>
      </template>

      <template v-if="this.total < 0">
        <table class="t_table">
          <tr>
            <td colspan="5">нет данных по выбранным параметрам</td>
          </tr>
        </table>
      </template>
    </div>
  </div>
</template>

<script>
import DateSelector from "@/components/DateSelector.vue";
import PeopleSelector from "@/components/PeopleSelector.vue";
import Loader from "@/components/Loader.vue";
import { dateFormatJS, dateFormatHuman } from "@/components-js/dateFormat";
import { DATE_MAX_INTERVAL, DATE_DEFAULT_INTERVAL } from "@/config/settings";
import { request } from "@/components-js/requestSrv";
import { lS } from "@/components-js/localStorage";
const loStorage = new lS();

export default {
  components: { DateSelector, PeopleSelector, Loader },
  data() {
    return {
      loading: false,
      date_begin: null,
      date_end: null,
      id_people: null,
      report: [],
      coworkers: [],
      total: 0,
      // show/hide people selector for admin/user
      peopleFlag: false,
    };
  },

  mounted() {
    // set default values for dates and point
    const d = new Date();

    this.date_begin = dateFormatJS(d);
    this.date_end = dateFormatJS(new Date(d.setDate(d.getDate() + DATE_DEFAULT_INTERVAL)));

    let tmp = loStorage.getObjectProp(this.$route.path, "date_begin");
    if (tmp) this.date_begin = tmp;

    tmp = loStorage.getObjectProp(this.$route.path, "date_end");
    if (tmp) this.date_end = tmp;

    // admin and user difference
    if (this.$root.user.roles && this.$root.user.roles.includes("admin")) {
      // show people selector for admin
      this.peopleFlag = true;
      // get id_people from localStorage
      tmp = parseInt(loStorage.getObjectProp(this.$route.path, "id_people"));
      if (tmp) this.id_people = tmp;
    } else {
      // user always see own table
      this.id_people = this.$root.user.uid;
    }

    // retreive data anyway
    this.tableDataRetrieve();
  },

  methods: {
    dateFormatHuman(d, w) {
      return dateFormatHuman(d, w);
    },

    typeTranslate(type) {
      if (type == "session") return "Сеанс";
      if (type == "advice") return "Консультация";
      return type;
    },
    async tableDataRetrieve() {
      this.loading = true;

      // check if they are dates?
      if (!new Date(this.date_begin) || !new Date(this.date_end)) return;

      // check dates for begin lower, then end
      if (new Date(this.date_begin) > new Date(this.date_end)) this.date_end = this.date_begin;

      // check for interval is not more, than date_max_interval days
      if (
        new Date(
          new Date(this.date_begin).setDate(
            new Date(this.date_begin).getDate() + DATE_MAX_INTERVAL
          )
        ) < new Date(this.date_end)
      )
        this.date_end = dateFormatJS(
          new Date(
            new Date(this.date_begin).setDate(
              new Date(this.date_begin).getDate() + DATE_MAX_INTERVAL
            )
          )
        );

      const data = await request(
        "/api/report/personal_turns",
        "POST",
        {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
        },
        this.$route.path
      );

      this.total = 0;
      for (let i in data) this.total += parseInt(data[i].hours);

      if (!this.total) this.total = -1;
      else {
        // if turns found, get coworkers
        this.coworkers = await request("/api/report/personal_turns_coworkers", "POST", {
          id_people: this.id_people,
          date_begin: this.date_begin,
          date_end: this.date_end,
        });

        // through each turn from data find coworkers
        let list = [];
        for (let i in data) {
          list = this.coworkers.filter(
            (el) =>
              data[i].turn_date == el.turn_date &&
              data[i].id_point == el.id_point &&
              ((data[i].utime_begin >= el.utime_begin && data[i].utime_begin <= el.utime_end) ||
                (data[i].utime_end > el.utime_begin && data[i].utime_end <= el.utime_end) ||
                (el.utime_begin > data[i].utime_begin && el.utime_end < data[i].utime_end) ||
                (data[i].utime_begin == el.utime_begin && data[i].utime_end == el.utime_end))
          );
          if (list.length) {
            // array of coworkers per turn
            data[i].coworkers = "";
            for (let l in list)
              data[i].coworkers +=
                (list[l].coworker_nickname
                  ? list[l].coworker_nickname
                  : list[l].coworker_name) + ", ";
          }
        }
      }
      this.report = data;
      this.loading = false;
    },
  },
};
</script>
